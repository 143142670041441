/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    h3: "h3",
    p: "p",
    br: "br"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Legal Notice"), "\n", React.createElement(_components.h3, null, "Provider"), "\n", React.createElement(_components.p, null, "Jens Müller", React.createElement(_components.br), "\n", "c/o Grosch Postflex #2649", React.createElement(_components.br), "\n", "Emsdettener Str. 10", React.createElement(_components.br), "\n", "48268 Greven", React.createElement(_components.br), "\n", "Germany"), "\n", React.createElement(_components.p, null, "No parcels or packages - acceptance is refused!"), "\n", React.createElement(_components.h3, null, "Contact"), "\n", React.createElement(_components.p, null, React.createElement("b", null, "E-Mail:"), " contact@lyricaloakwhisper.com", React.createElement(_components.br), "\n", React.createElement("b", null, "Phone:"), " +49 176 63078285"), "\n", React.createElement(_components.h3, null, "Content"), "\n", React.createElement(_components.p, null, "Jens Müller (Address as above)."), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement("br"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
