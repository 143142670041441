import GATSBY_COMPILED_MDX from "/opt/build/repo/content/legal/legalnotice/index.mdx";
import React from "react";
import {graphql} from "gatsby";
const LeafTemplate = ({data, location, children}) => {
  return React.createElement(React.Fragment, null, React.createElement("div", null, React.createElement("div", {
    className: "prose mt-24"
  }, children)));
};
export const query = graphql`
  query ($id: String!) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        slug
      }
    }
  }
`;
LeafTemplate
export default function GatsbyMDXWrapper(props) {
  return React.createElement(LeafTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
